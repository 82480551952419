<template>
  <PublicLayout>
    <section class="bg-white">
      <div class="container mx-auto lg:pt-14">
        <slot />
      </div>
    </section>
  </PublicLayout>
</template>

<style scoped>

</style>
<script setup lang="ts">
import PublicLayout from "~/layouts/public.vue";

const { $seo } = useNuxtApp();
const { t } = useI18n();
const route = useRoute();
const localePath = useLocalePath();
const config = useRuntimeConfig();

const {workflow} = useMarketplaceMatching();
const currentState = computed(() => workflow.currentState);

watch(currentState, (value) => {
  console.log(value);
})

useSeoMeta({
  title: $seo.getTitle('seo./matching.title'),
  description: $seo.getDescription(),
})

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: [
      defineListItem({
        item: {
          '@id': config.public.url + localePath(`/`),
          name: $seo.getTitle(`seo./.title`)
        }
      }),
      defineListItem({
        item: {
          '@id': config.public.url + route.fullPath,
          name: $seo.getTitle('seo./matching.title')
        }
      }),
    ]
  }),
])
</script>
